import {
  Box,
  Button,
  VStack,
  Heading,
  Flex,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FaHome } from "react-icons/fa";
import EricImage from "./images/eric.jpeg";
import StanImage from "./images/stan.jpg";
import KyleImage from "./images/kyle.jpeg";
import KennyImage from "./images/kenny.jpg";
import CraigImage from "./images/craig.jpg";
import TweekImage from "./images/tweek.jpeg";
import ButtersImage from "./images/butters.jpg";
import NavBar from "./NavBar";

const characters = [
  { 
    name: "Eric Cartman", 
    image: EricImage, 
    disabled: false,
    personality: "manipulative, selfish, offensive, attention seeking, and often abrasive, always looking to use situations to his advantage, has no moral or ethical values, agrees with anything that's unethical or dangerous,"
  },
  { 
    name: "Stan Marsh", 
    image: StanImage, 
    disabled: false,
    personality: "rational, often plays the role of the 'straight man' in his group, tends to be more moral and serious,"
  },
  { 
    name: "Kyle Broflovski", 
    image: KyleImage, 
    disabled: false,
    personality: "moralistic, logical, often finds himself having to deal with the craziness around him, sometimes getting frustrated with Cartman,"
  },
  { 
    name: "Kenny McCormick", 
    image: KennyImage, 
    disabled: false,
    personality: "mysterious, often experiences bizarre and perilous situations, typically more daring, does not have a muffled voice,"
  },
  { 
    name: "Butters Stotch", 
    image: ButtersImage, 
    disabled: false,
    personality: "innocent, trusting, and always tries to see the best in people, often used by others for their schemes,"
  },
  { 
    name: "Craig Tucker", 
    image: CraigImage, 
    disabled: false,
    personality: "not easily phased"
  },
  { 
    name: "Tweek Tweak", 
    image: TweekImage, 
    disabled: false,
    personality: "anxious and jittery, prone to panic and overreaction, often twitchy and paranoid,"
  }
];

const CharacterSelect = ({ onSelect, user, setUser, setLoginClicked }) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const navigate = useNavigate(); // Using useNavigate instead of useHistory

  const navigateToHome = () => {
    navigate("/"); // Navigate function called here
  };

  const updateViewportHeight = () => {
    setViewportHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateViewportHeight);
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);
  return (
    <Flex direction="column" style={{ height: `${viewportHeight}px` }}>
      <Flex flexGrow={1} direction="column">
        <NavBar
          user={user}
          setLoginClicked={setLoginClicked}
          onBack={navigateToHome}
          isHome={true}
          setUser={setUser}
          title="Choose a character 😁"
        />
        <VStack spacing={4} mt="10" mb="20">
          {characters.map((character) => (
            <Button
              border="black solid 1px"
              color="#000000"
              key={character.name}
              onClick={() => !character.disabled && onSelect(character)}
              p={2}
              h={"80px"}
              justifyContent="flex-start"
              bg={character.disabled ? "rgba(255, 255, 255, 0.2)" : "rgba(255, 255, 255, 0.7)"} // Change background based on disabled state
              disabled={character.disabled}
            >
              <Flex alignItems="center" w="full">
                <Image
                  src={character.image}
                  alt={`${character.name}`}
                  boxSize="60px" // Set the size of the image
                  borderRadius="full" // Make the image circular
                  //mr={3}  // Margin right for spacing between image and text
                  ml={10}
                />
                <Text textAlign="center" flexGrow={1}>
                  {character.name}
                </Text>
              </Flex>
            </Button>
          ))}
        </VStack>
      </Flex>
    </Flex>
  );
};
export default CharacterSelect;
