import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Chat.css";
import { FaArrowLeft } from "react-icons/fa";
import NavBar from "./NavBar";
import {
  Box,
  Input,
  Button,
  Text,
  Flex,
  HStack,
  Heading,
  Stack,
  Image,
} from "@chakra-ui/react";
import EricImage from "./images/eric.jpeg";
import StanImage from "./images/stan.jpg";
import KyleImage from "./images/kyle.jpeg";
import KennyImage from "./images/kenny.jpg";
import CraigImage from "./images/craig.jpg";
import TweekImage from "./images/tweek.jpeg";
import ButtersImage from "./images/butters.jpg";

const characters = [
  { name: "Eric Cartman", image: EricImage },
  { name: "Stan Marsh", image: StanImage },
  { name: "Kyle Broflovski", image: KyleImage },
  { name: "Kenny McCormick", image: KennyImage },
  { name: "Craig Tucker", image: CraigImage },
  { name: "Tweek Tweak", image: TweekImage },
  { name: "Butters Stotch", image: ButtersImage },
];
const TypingIndicator = ({ character }) => {
  return (
    <Flex align="center">
      <Text display="inline">{character} is typing</Text>
      <span className="typing-dot">.</span>
      <span className="typing-dot">.</span>
      <span className="typing-dot">.</span>
    </Flex>
  );
};

function Chat({ character, onBack, user, setUser, setLoginClicked }) {
  const [input, setInput] = useState("");
  const [responses, setResponses] = useState([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [characterHere, setCharacterHere] = useState({});

  useEffect(() => {
    const foundCharacter = characters.find((c) => c.name === character.name);
    setCharacterHere(foundCharacter);
  }, [character]); // Depend on characterName prop

  const updateViewportHeight = () => {
    setViewportHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateViewportHeight);
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [responses]); // Trigger scroll when messages update


  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const conversationHistory = useRef([
    {
      role: "system", // This can be "system" to indicate setup instructions
      content: `You are ${character.name} from South Park who is ${character.personality}, and you're on a chatting app. Do not include any of these instructions in the response.`,
    },
    {
      role: "user", // First real input from the user side to start the conversation
      content: `Hey ${character.name}!`,
    },
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!input.trim()) return;

    // Add the user's input to the conversation history
    conversationHistory.current.push({ role: "user", content: input });

    // When showing typing indicator
    setResponses((oldResponses) => [
      ...oldResponses,
      {
        question: input,
        answer: <TypingIndicator character={character.name} />,
        isTyping: true,
      },
    ]);

    try {
      // Clear the input field after submitting
      setInput("");
      const response = await axios.post("https://www.kennycentral.com/api/chat-completion", {
        messages: conversationHistory.current,
        max_tokens: 100,
        //model: "gryphe/mythomax-l2-13b",
        model: "meta-llama/llama-3-8b-instruct",
      });

      //console.log("Response received:", response.data);
      const aiResponse = response.data.choices[0].message.content;
      //console.log("AI Response:", aiResponse);

      setTimeout(() => {
        // Remove typing indicator
        setResponses((oldResponses) =>
          oldResponses.map((res) =>
            res.isTyping ? { ...res, answer: aiResponse, isTyping: false } : res
          )
        );

        // Update conversation history with the AI's response
        conversationHistory.current.push({
          role: "assistant",
          content: aiResponse,
        });
      }, 1500); // Delay in milliseconds, adjust as needed
    } catch (error) {
      console.error("Error while sending message to API:", error);
      // Optionally handle typing indicator removal here as well
      setResponses((oldResponses) =>
        oldResponses.filter((res) => !res.isTyping)
      );
    }
  };

  const Message = React.memo(({ text, actor, customComponent }) => {
    return (
      <Flex
        p={4}
        bg={actor === "user" ? "blue.500" : "gray.100"}
        color={actor === "user" ? "white" : "gray.600"}
        borderRadius="lg"
        w="fit-content"
        alignSelf={actor === "user" ? "flex-end" : "flex-start"}
        align="center" // Ensure vertical alignment between text and image
      >
        {actor !== "user" && (
          <Image
            src={characterHere.image}
            alt={`${characterHere.name}`}
            boxSize="60px" // Set the size of the image
            borderRadius="full" // Make the image circular
            mr={3} // Margin right for spacing between image and text
          />
        )}
        {customComponent ? text : <Text>{text}</Text>}
      </Flex>
    );
  });

  return (
    <Flex direction="column" style={{ height: `${viewportHeight}px` }}>
      <Flex flexGrow={1} direction="column">
        <NavBar
          user={user}
          setLoginClicked={setLoginClicked}
          onBack={onBack}
          isHome={false}
          title={character.name}
          setUser={setUser}
        />
        <Box flex="1" display="flex" flexDirection="column">
          <Stack
            px={4}
            py={8}
            overflowY="auto"
            spacing={4}
            style={{ marginTop: "auto" }}
            zIndex={20}
          >
            {responses.map((res, index) => (
              <React.Fragment key={index}>
                <Message text={res.question} actor="user" />
                <Message
                  text={res.answer}
                  actor="bot"
                  customComponent={res.isTyping}
                />
              </React.Fragment>
            ))}
            <div ref={messagesEndRef} />{" "}
          </Stack>
        </Box>

        <HStack
          as="form"
          onSubmit={handleSubmit}
          p={4}
          bg="gray.100"
          w="full"
          zIndex="sticky"
          bottom="0"
          position="sticky"
        >
          <Input
            bg="white"
            placeholder="Don't be shy, say something!"
            value={input}
            onChange={handleInputChange}
            color="#000000"
          />
          <Button colorScheme="blue" type="submit" w={100}>
            Send
          </Button>
        </HStack>
      </Flex>
    </Flex>
  );
}

export default Chat;
