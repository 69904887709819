import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuOptionGroup,
  IconButton,
} from "@chakra-ui/react";
import { FaHome, FaArrowLeft } from "react-icons/fa";
import axios from "axios";

//import { ChevronDownIcon } from "@chakra-ui/icons";

const NavBar = ({ user, onBack, isHome, title, onSignOut, setUser, setLoginClicked }) => {
  const avatarUrl =
    user && user.avatar
      ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
      : "path_to_default_avatar_if_needed.png"; // Default avatar if needed

      function handleSignOut() {
        axios.post('https://www.kennycentral.com/api/signout', {}, { withCredentials: true })
          .then(response => {
            console.log(response.data.message);
            // Clear user from state
            setUser(null);
            setLoginClicked(false);
          })
          .catch(error => console.error('Error signing out:', error));
      }
      

  return (
    <HStack
      p={3}
      bg="rgba(0, 0, 0, 0.4)"
      zIndex="sticky"
      top="0"
      position="sticky"
      width="full"
    >
      {isHome ? (
        <Button
          onClick={() => onBack()}
          bg="rgba(0, 0, 0, 0.4)"
          w={50}
          zIndex={10}
          border="white solid 1px"
        >
          <FaHome color="white" />
        </Button>
      ) : (
        <Button
          onClick={() => onBack()}
          bg="rgba(0, 0, 0, 0.4)"
          w={50}
          zIndex={10}
          border="white solid 1px"
        >
          <FaArrowLeft color="white" />
        </Button>
      )}

      <Box flex="1" textAlign="center" left={0} right={0} position="absolute">
        <Heading size="md" color="white">
          {title}
        </Heading>
      </Box>

      {user ? (
        <Menu>
          <MenuButton
            //as={IconButton}
            //variant="outline"
            borderRadius="full"
            w="40px"
            right={4}
            position="absolute"
          >
            <Flex direction="column" align="center">
              {" "}
              <Image
                borderRadius="full"
                boxSize="40px"
                src={avatarUrl}
                alt={`${user.username}'s avatar`}
              />
              {/*
              <Text fontWeight="bold" fontSize={10}>
                {user.username}
              </Text>{" "}
      */}
            </Flex>{" "}
          </MenuButton>
          <MenuList>
            {/*<MenuItem onClick={() => console.log("Profile Clicked")}>Profile</MenuItem>
            <MenuItem onClick={() => console.log("Settings Clicked")}>Settings</MenuItem>
      <MenuDivider />*/}
          <MenuOptionGroup title={user.username}>
            <MenuItem
              //_hover={{ bg: "none" }}
              //_focus={{ bg: "none", borderColor: "none" }}
              onClick={handleSignOut}
            >
              Sign Out
            </MenuItem>
            </MenuOptionGroup>

          </MenuList>
        </Menu>
      ) : (
        <>
        {/*
        <Text
          fontFamily={"sans-serif"}
          fontSize="md"
          right={4}
          position="absolute"
        >
          Sign in
        </Text>
      */}
         <Text
         fontFamily={"sans-serif"}
         fontSize="md"
         right={4}
         position="absolute"
       ></Text>
       </>
      )}
    </HStack>
  );
};

export default NavBar;
